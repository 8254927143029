// eslint-disable-next-line import/prefer-default-export
export const ctrlBtnByRequestStatus = ({ enter }) => {
  // const buttons = document.getElementsByTagName('button');
  // for (let i = 0; i < buttons.length; i++) {
  //   if (enter) {
  //     if (!buttons[i].disabled) {
  //       buttons[i].className = `${buttons[i].className} globalCtrl`;
  //     }
  //     buttons[i].disabled = true;
  //   } else if (buttons[i].className.indexOf('globalCtrl') !== -1) {
  //     buttons[i].disabled = false;
  //     buttons[i].className = `${buttons[i].className}`.replace(' globalCtrl', '');
  //   }
  // }
};
