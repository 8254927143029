import { createRoutes } from '@/utils/core';
import BasicLayout from '@/layouts/BasicLayout';
import UserLayout from '@/layouts/UserLayout';
import MobileLayout from '@/layouts/MobileLayout';
import Page403 from './Pages/403';
import NotFound from './Pages/404';
import Page500 from './Pages/500';
import Login from './Login';
import Register from './Register';
import Home from './Home/index'; //首页
import Fabricmanagement from './System/Fabricmanagement'; //织物管理
import Hospitalmanagement from './System/Hospitalmanagement/index'; //医院管理
import Departmentmanagement from './System/Departmentmanagement/index'; //科室管理
import Smartcabinetmanagement from './System/Smartcabinetmanagement/index'; //智能柜管理
import Authmanagement from './System/Authmanagement/index'; //权限管理
import Usermanagement from './System/Usermanagement/index'; //用户管理
import Rolemanagement from './System/Rolemanagement/index'; //角色管理
import AlertManagement from './System/AlertManagement/index'; //告警处理
import ClientFileManagement from './System/ClientFileManagement/index'; //客户端文件管理
import RfidRegister from './RfidManagement/RfidRegister/index'; //RFID标签绑定
import RfidUnbind from './RfidManagement/RfidUnbind/index'; //RFID标签解绑
import RfidQueryAndManagement from './RfidManagement/RfidQueryAndManagement/index'; //RFID标签管理
import RfidLossReport from './RfidManagement/RfidLossReport/index'; //RFID标签报损
import RfidFabricToBeWashedInventory from './RfidManagement/RfidFabricToBeWashedInventory/index'; //RFID待洗织物清点
import AbnormalReport from './RfidManagement/AbnormalReport/index'; //异常织物上报
import ScrapCloth from './RfidManagement/ScrapCloth/index'; //织物报废
import LogisticsTaskManagement from './LogisticsTaskManagement/LogisticsTaskManagement/index'; //物流任务管理
import ClothTaskManagement from './ClothTaskManagement/ClothTaskManagement/index'; //织物收送任务管理
import BaggedClothManagement from './ClothTaskManagement/BaggedClothManagement/index'; //已装袋织物管理
import BaggedCount from './ClothTaskManagement/BaggedCount/index'; //装袋清点
import ReceivedCount from './ClothTaskManagement/ReceivedCount/index'; //接收清点
import ReplenishmentPredictions from './Statistic/ReplenishmentPredictions/index'; //待补充织物分析
import ReceiveOrder from './Statistic/ReceiveOrder/index'; //织物接收单查询
import FarePrediction from './Statistic/FarePrediction/index'; //结算费用统计
import FabricScrapRecords from './Statistic/FabricScrapRecords'; //织物报废记录
import RFIDChipDamageReport from './Statistic/RFIDChipDamageReport'; // RFID芯片报损记录
import CabinetOperationRecord from './Statistic/CabinetOperationRecord'; //柜格操作记录
import FabricBagInventoryRecord from './Statistic/FabricBagInventoryRecord'; //织物袋清点记录
import LogisticsHandoverRecord from './Statistic/LogisticsHandoverRecord'; //物流交接记录
import RFIDFabricUsageRecord from './Statistic/RFIDFabricUsageRecord'; //RFID织物使用记录
import RFIDFabricUsageStatistic from './Statistic/RFIDFabricUsageStatistic'; //RFID织物使用统计
import TaskExecutionRecord from './Statistic/TaskExecutionRecord'; //任务执行记录
import UserRegistrationRecord from './Statistic/UserRegistrationRecord'; //用户登录记录
import RFIDFabricEntryAndExitRecordDetails from './Statistic/RFIDFabricEntryAndExitRecordDetails'; //RFID织物进出柜记录明细
import CabinetInventoryRecordDetails from './Statistic/CabinetInventoryRecordDetails'; //柜格清点记录明细
import RfidWashInventoryRecord from './Statistic/RfidWashInventoryRecord'; //RFID洗涤清点记录
import ManualWashInventoryRecord from './Statistic/ManualWashInventoryRecord'; //手工洗涤清点记录
import ClothDeliveredStatistics from './Statistic/ClothDeliveredStatistics'; //织物收送统计
import SystemExceptionLogging from './Statistic/SystemExceptionLogging'; //系统异常上报记录
import PersonalInfo from './Mobile/PersonalInfo/index';
import AccountBinding from './Mobile/AccountBinding/index';
import WarningPage from './Mobile/WarningPage/index';
import PersonalStorageInfo from './Mobile/PersonalStorageInfo/index';
import Blank from './Blank/index';
import EC from './Widgets/Charts/EC/index';
import WarehouseManagement from './WarehouseManagement/WarehouseManagement/index'; // 仓库管理
import CleanClothesDeliveryStatistic from './Statistic/CleanClothesDeliveryStatistic'; //净衣送室回收统计
import SmartCabinetMonitoring from './System/SmartCabinetMonitoring'; //智能柜监控
import RFIDInventoryCheckRecord from './Statistic/RFIDInventoryCheck/index'; //RFID织物大盘点记录
import UnreturnedFabricOverdueStatistics from './Statistic/UnreturnedFabricOverdueStatistics/index'; // 织物使用未归还逾期统计
import ManualInoutWarehouse from './WarehouseManagement/ManualInoutWarehouse/index'; // 手工出入仓
import OrderManagement from './OrderManagement/OrderManagement/index'; // 科室下单管理
import Order from './Mobile/Order/index'; // 我的订单
import DepartmentOrderStatistics from './OrderManagement/DepartmentOrderStatistics/index'; // 科室下单统计
import CleanClothesDeliveryToDepartmentStatistics from './Statistic/CleanClothesDeliveryToDepartmentStatistics/index'; // 净衣送室统计
import ClothCategoryGroupManagement from './System/ClothCategoryGroupManagement/index'; // 织物类型组管理
import OperationalLog from './System/OperationalLog/index'; // 操作日志
import RfidQuery from './RfidManagement/RfidQuery/index'; // RFID织物查询

/**
 * 主路由配置
 *
 * path 路由地址
 * component 组件
 * indexRoute 默认显示路由
 * childRoutes 所有子路由
 * NotFound 路由要放到最下面，当所有路由当没匹配到时会进入这个页面
 */
const routesConfig = (app) => [
  {
    path: '/mobile',
    title: '杏林伟鹏智能织物管理',
    indexRoute: '/mobile/login',
    component: MobileLayout,
    childRoutes: [
      PersonalInfo(app),
      AccountBinding(app),
      PersonalStorageInfo(app),
      WarningPage(app),
      Order(app),
      Blank(app),
    ],
  },
  {
    path: '/sign',
    title: '登录',
    indexRoute: '/sign/login',
    component: UserLayout,
    childRoutes: [Login(app), Register(app), NotFound()],
  },
  {
    path: '/',
    title: '智能织物管理',
    component: BasicLayout,
    indexRoute: '/home',
    childRoutes: [
      Home(app),
      Fabricmanagement(app),
      Hospitalmanagement(app),
      Departmentmanagement(app),
      Smartcabinetmanagement(app),
      Authmanagement(app),
      Rolemanagement(app),
      Usermanagement(app),
      RfidQueryAndManagement(app),
      RfidRegister(app),
      RfidUnbind(app),
      RfidLossReport(app),
      AbnormalReport(app),
      ScrapCloth(app),
      BaggedCount(app),
      ReceivedCount(app),
      ClothTaskManagement(app),
      BaggedClothManagement(app),
      ReplenishmentPredictions(app),
      LogisticsTaskManagement(app),
      RfidFabricToBeWashedInventory(app),
      RfidWashInventoryRecord(app),
      FarePrediction(app),
      ReceiveOrder(app),
      FabricScrapRecords(app),
      RFIDChipDamageReport(app),
      CabinetOperationRecord(app),
      FabricBagInventoryRecord(app),
      LogisticsHandoverRecord(app),
      RFIDFabricUsageRecord(app),
      TaskExecutionRecord(app),
      UserRegistrationRecord(app),
      RFIDFabricEntryAndExitRecordDetails(app),
      CabinetInventoryRecordDetails(app),
      RFIDFabricUsageStatistic(app),
      ManualWashInventoryRecord(app),
      ClientFileManagement(app),
      AlertManagement(app),
      ClothDeliveredStatistics(app),
      SystemExceptionLogging(app),
      CleanClothesDeliveryStatistic(app),
      SmartCabinetMonitoring(app),
      RFIDInventoryCheckRecord(app),
      UnreturnedFabricOverdueStatistics(app),
      CleanClothesDeliveryToDepartmentStatistics(app),
      WarehouseManagement(app),
      ManualInoutWarehouse(app),
      OrderManagement(app),
      ClothCategoryGroupManagement(app),
      DepartmentOrderStatistics(app),
      OperationalLog(app),
      RfidQuery(app),
      EC(app),
      Blank(app),
      NotFound(),
    ],
  },
];

export default (app) => createRoutes(app, routesConfig);
