import {dynamicWrapper, createRoute} from '@/utils/core';

const routesConfig = (app) => ({
  path: '/rifdInventoryCheckRecord',
  title: 'RFID织物大盘点记录',
  component: dynamicWrapper(app, [import('./model')], () => import('./components/index')),
  exact: true
});

export default (app) => createRoute(app, routesConfig);
