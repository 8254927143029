import {dynamicWrapper, createRoute} from '@/utils/core';

const routesConfig = (app) => ({
  path: '/unreturnedFabricOverdueStatistics',
  title: '织物使用未归还逾期统计',
  component: dynamicWrapper(app, [import('./model')], () => import('./components/index')),
  exact: true
});

export default (app) => createRoute(app, routesConfig);
