/**
 * 模拟请求数据
 * @param {FetchMock} fetchMock 当现有条件不满足时，可以使用fetchMock来进行扩展
 * @param {function} delay 增加延迟时间 ms 例: delay(mockData) 或 delay(mockData, 200)
 * @param {function} mock 使用mock生成数据，例:

   mock({
     'string|1-10': '★' // 生成最少1颗，最多10颗星字符
   })

   // {'string': '★★★★★★'} 

  更多用法参考 http://mockjs.com/examples.html
 */
export default ({ fetchMock, delay, mock, toSuccess, toError }) => {
  // 如果现有扩展不满足需求，可以直接使用fetchMock方法
  // fetchMock.mock(/httpbin.org\/post/, {/* response */}, {/* options */});

  return {
    '/api/user/login': (options) => {
      if (options.body) {
        const user = JSON.parse(options.body);
        if (user.userName === 'admin' && user.password === 'admin') {
          return toSuccess(
            mock({
              userName: 'admin', // 用户名
              name: '@cname', // 中文名称
              'age|1-100': 100, // 100以内随机整数
              birthday: '@date("yyyy-MM-dd")', // 日期
              city: '@city(true)', // 中国城市
              phone: /^1[385][1-9]\d{8}/, // 手机号
              token: '@guid', // token
            }),
            400
          );
        } else {
          return toError('用户名或密码错误 admin/admin');
        }
      } else {
        return toError('请输入用户名和密码');
      }
    },
    '/api/user/register': (options) => toSuccess(),
    '/api/user/menu': (options) =>
      toSuccess(
        [
          {
            name: '首页',
            path: '/home',
          },
          {
            name: '系统管理',
            path: '/system',
            children: [
              {
                name: '织物类型管理',
                path: '/clothCategoryManagement',
              },
              {
                name: '织物类型组管理',
                path: '/clothCategoryGroupManagement',
              },
              {
                name: '机构管理',
                path: '/orgManagement',
              },
              {
                name: '科室管理',
                path: '/departmentManagement',
              },
              {
                name: '智能柜管理',
                path: '/smartCabinetManagement',
              },
              {
                name: '智能柜监控',
                path: '/smartCabinetMonitoring',
              },
              {
                name: '用户管理',
                path: '/userManagement',
              },
              {
                name: '角色管理',
                path: '/roleManagement',
              },
              {
                name: '权限管理',
                path: '/authManagement',
              },
              {
                name: '告警处理',
                path: '/alertManagement',
              },
              {
                name: '客户端文件管理',
                path: '/clientFileManagement',
              },
              {
                name: '操作日志',
                path: '/operationalLog',
              }
            ],
          },
          {
            name: 'RFID管理',
            path: '/rfidManagement',
            children: [
              {
                name: 'RFID标签查询',
                path: '/rfidQuery',
              },
              {
                name: 'RFID标签管理',
                path: '/rfidQueryAndManagement',
              },
              {
                name: 'RFID标签绑定',
                path: '/rfidRegister',
              },
              {
                name: 'RFID标签解绑',
                path: '/rfidUnbind',
              },
              {
                name: 'RFID待洗织物清点',
                path: '/rfidFabricToBeWashedInventory',
              },
              {
                name: '异常织物上报',
                path: '/abnormalClothReport',
              },
              {
                name: '织物报废',
                path: '/scrapCloth',
              },
            ],
          },
          {
            name: '物流任务管理',
            path: '/logisticsTask',
            children: [
              {
                name: '物流任务管理',
                path: '/logisticsTaskManagement',
              },
            ],
          },
          {
            name: '织物收送任务管理',
            path: '/clothTask',
            children: [
              {
                name: '织物收送任务管理',
                path: '/clothTaskManagement',
              },
              {
                name: '实时已装袋织物管理',
                path: '/baggedClothManagement',
              },
              {
                name: '装袋清点',
                path: '/baggingCheck',
              },
              {
                name: '接收清点',
                path: '/receivingCheck',
              },
            ],
          },
          {
            name: '订单管理',
            path: '/orderManagement',
            children: [
              {
                name: '科室下单管理',
                path: '/orderManagement',
              },
              {
                name: '科室下单统计',
                path: '/departmentOrderStatistics',
              },
            ],
          },
          {
            name: '仓库管理',
            path: '/warehouse',
            children: [
              {
                name: '仓库管理',
                path: '/warehouseManagement',
              },
              {
                name: '手工出入仓',
                path: '/manualInoutWarehouse',
              },
            ],
          },
          {
            name: '数据统计',
            path: '/statistic',
            children: [
              {
                name: '待补充织物分析',
                path: '/replenishmentPredictions',
              },
              {
                name: '织物接收单查询',
                path: '/acceptBillQuery',
              },
              {
                name: '净衣送室统计',
                path: '/cleanClothesToDepartmentStatistics',
              },
              {
                name: '净衣送室回收统计',
                path: '/directDeliveryClothRecyclingStatistics',
              },
              {
                name: 'RFID织物使用记录',
                path: '/rfidFabricUsageRecord',
              },
              {
                name: 'RFID织物使用统计',
                path: '/rfidFabricUsageStatistic',
              },
              {
                name: '结算费用统计',
                path: '/farePrediction',
              },
              {
                name: '织物报废记录',
                path: '/fabricScrapRecords',
              },
              {
                name: 'RFID织物大盘点记录',
                path: '/rifdInventoryCheckRecord',
              },
              {
                name: '织物使用未归还逾期统计',
                path: '/unreturnedFabricOverdueStatistics',
              },
              {
                name: 'RFID芯片报损记录',
                path: '/rfidChipDamageReport',
              },
              {
                name: '柜格操作记录',
                path: '/cabinetCellOpRecord',
              },
              {
                name: '织物袋清点记录',
                path: '/fabricBagInventoryRecord',
              },
              {
                name: '物流交接记录',
                path: '/logisticsHandoverRecord',
              },
              {
                name: '任务执行记录',
                path: '/taskExecutionRecord',
              },
              {
                name: '用户登录记录',
                path: '/userLoginRecord',
              },
              {
                name: 'RFID织物进出柜记录明细',
                path: '/rfidFabricEntryAndExitRecordDetails',
              },
              {
                name: '柜格清点记录明细',
                path: '/cabinetCellCheckRecord',
              },
              {
                name: 'RFID洗涤清点记录',
                path: '/rfidWashInventoryRecord',
              },
              {
                name: '手工洗涤清点记录',
                path: '/manualWashInventoryRecord',
              },
            ],
          },
        ],
        400
      ),
  };
};
