import $$ from 'cmn-utils';
import modelEnhance from '@/utils/modelEnhance';
import { getUserInfo } from '@/utils/loginModule';
export default modelEnhance({
  namespace: 'global',
  state: {
    menu: [],
    flatMenu: [],
  },

  effects: {
    *getMenu({ payload }, { call, put }) {
      let data;
      if (
        process.env.REACT_APP_BUILD_MODE === 'PROD' ||
        process.env.REACT_APP_BUILD_MODE === 'OFFLINE_PROD'
      ) {
        data = staticGetMenuFromUserAuthInfo(); // 生产环境
      } else {
        const { data: testData } = yield call(getMenu, payload); // 测试环境
        data = testData;
      }

      if (data) {
        const _data = [{ name: '首页', path: '/home' }].concat(data);
        const loopMenu = (menu, pitem = {}) => {
          menu.forEach((item) => {
            if (pitem.path) {
              item.parentPath = pitem.parentPath
                ? pitem.parentPath.concat(pitem.path)
                : [pitem.path];
            }
            if (item.children && item.children.length) {
              loopMenu(item.children, item);
            }
          });
        };
        loopMenu(_data);

        yield put({
          type: 'getMenuSuccess',
          payload: _data,
        });
      }
    },

    *fetchCurrent({ payload }, { call, put }) {
      try {
        const { success, message, data, code } = yield call(getUserInfo, {});
        const _data = {
          ...data,
          createdTime: Date.now(), // user 信息 LocalStorage缓存创建日期
        };
        $$.setStore('user', _data);
      } catch (error) {}
    },
  },

  reducers: {
    getMenuSuccess(state, { payload }) {
      return {
        ...state,
        menu: payload,
        flatMenu: getFlatMenu(payload),
      };
    },
  },
});

export function getFlatMenu(menus) {
  let menu = [];
  menus.forEach((item) => {
    if (item.children) {
      menu = menu.concat(getFlatMenu(item.children));
    }
    menu.push(item);
  });
  return menu;
}

export async function getMenu(payload) {
  return $$.post('/user/menu', payload);
}

// 获取当前登录用户的权限信息
export function staticGetMenuFromUserAuthInfo() {
  const menuAuthList = $$.getStore('user')?.menuAuthList || [];
  return menuAuthList;
}
