import { message } from 'antd';
import axios from './_axios';
import { ctrlBtnByRequestStatus } from '@/utils/renderStatusCtrl';
import $$ from 'cmn-utils';
import { routerRedux } from 'dva';
import { loginUrl, terminalCode } from '@/services/loginModule';
import _ from 'lodash';

// 开发环境FLAG，开发环境：true，生产环境：false
export const isDevENV =
  process.env.REACT_APP_BUILD_MODE === 'DEV' ? true : false;
//axios的使用
export const methodtype = {
  GET: 'GET',
  POST: 'POST',
};

const counterStrLength = (input) => {
  const inputStr = JSON.stringify(input);
  var totalLength = 0;
  /* 计算utf-8编码情况下的字符串长度 */
  for (var i = 0; i < inputStr.length; i++) {
    if (inputStr.charCodeAt(i) <= parseInt('0x7F')) {
      totalLength += 1;
    } else if (inputStr.charCodeAt(i) <= parseInt('0x7FF')) {
      totalLength += 2;
    } else if (inputStr.charCodeAt(i) <= parseInt('0xFFFF')) {
      totalLength += 3;
    } else if (inputStr.charCodeAt(i) <= parseInt('0x1FFFFF')) {
      totalLength += 4;
    } else if (inputStr.charCodeAt(i) <= parseInt('0x3FFFFFF')) {
      totalLength += 5;
    } else {
      totalLength += 6;
    }
  }
  if (totalLength >= 65534) {
    message.error('超过最大字节限制！');
    return false;
  }
  return true;
};

export const successCodeDict = {
  200: true,
  201: false,
  202: false,
  203: false,
};

const errorCodeDict = {
  500: false,
  501: false,
};

const invalidTokenCode = {
  400: true,
  401: true,
};

export const toLogin = () => {
  // 清除用户登陆信息
  window.localStorage.clear();
  window.location.hash = '/sign/login';
};

export const toMobileWarningPage = () => {
  // 清除用户登陆信息
  window.localStorage.clear();
  window.location.hash = '/mobile/warningPage';
};

export function Request({
  absoluteUrl,
  url,
  params = {},
  method = methodtype.POST,
  callback,
  successMsg,
  errorMsg,
  noSuccessMessage = true,
  noErrorMessage,
  customHeaders = {},
  responseType,
  isForMobile = false,
}) {
  if (!$$.getStore('user') && url !== loginUrl && !isForMobile) {
    toLogin();
    return;
  }
  if (
    absoluteUrl &&
    !$$.getStore('user') &&
    absoluteUrl !== loginUrl &&
    !isForMobile
  ) {
    toLogin();
    return;
  }
  if (!params || (!absoluteUrl && !url))
    throw new Error('Post请求应该包含有效的Url和Payload！');

  const { token } = $$.getStore('user') || {};

  if (!counterStrLength(params)) return;
  ctrlBtnByRequestStatus({ enter: true });
  // eslint-disable-next-line consistent-return
  let headers = {
    ...customHeaders,
    Terminal: isForMobile ? terminalCode.MINA : terminalCode.MANAGEMENT_SYSTEM,
  };
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }
  let filteredNullParams;
  if (_.isArray(params)) {
    filteredNullParams = params;
  } else if (Object.prototype.toString.call(params) === '[object Object]') {
    filteredNullParams = {};
    for (let i in params) {
      if (
        typeof params?.[i] != 'undefined' &&
        params?.[i] != null &&
        i?.length
      ) {
        filteredNullParams[i] = params?.[i];
      }
    }
  } else {
    filteredNullParams = params;
  }

  let axiosProps = {
    method,
    url: absoluteUrl || (isDevENV ? `/api${url}` : `/api/cms/service/v1${url}`), // 测试环境下代理
    // url: absoluteUrl || `/api/cms/service/v1${url}`, // 生产环境
    data: filteredNullParams,
    headers,
  };
  // 返回类型自定义
  if (responseType) {
    axiosProps['responseType'] = responseType;
  }
  return axios(axiosProps)
    .then((res) => {
      if (responseType) {
        callback(res);
        return;
      }
      ctrlBtnByRequestStatus({ enter: false });
      if (res?.data?.code === '401' && !isForMobile) {
        // message.error(`错误代码: ${res?.data?.code}, 用户登录信息失效！`);
        toLogin();
        return;
      }
      if (res?.headers?.['new-token']) {
        const { token, ..._user } = $$.getStore('user');
        $$.setStore('user', {
          ..._user,
          createdTime: Date.now(), // user 信息 LocalStorage缓存创建日期
          token: res?.headers?.['new-token'],
        });
      }
      const result = {
        data: res?.data?.data,
        success: successCodeDict[res?.data?.code] || false,
        message: res?.data?.message,
        code: res?.data?.code,
      };
      if (successCodeDict[result.code]) {
        callback && callback(result.data);
        if (!noSuccessMessage) {
          message.success(successMsg || result.message || '操作成功!');
        }
      } else {
        callback && callback(result.data);
        if (!noErrorMessage) {
          message.error(errorMsg || result.message || '操作失败!');
        }
      }
      return result;
    })
    .catch((error) => {
      if (error.response) {
        const result = {
          data: error.response?.data?.data,
          success: successCodeDict[error.response?.data?.code] || false,
          message: error.response?.data?.message,
          code: error.response?.data?.code,
        };
        callback && callback(result.data);
        // 请求成功发出且服务器也响应了状态码，但状态代码超出了 2xx 的范围
        if (error.response.status === 401 && !isForMobile) {
          // message.error(
          //   `错误代码:${error.response.status}, 用户登录信息失效！`
          // );
          toLogin();
          return result;
        }
      } else if (error.request) {
        // 请求已经成功发起，但没有收到响应
        // `error.request` 在浏览器中是 XMLHttpRequest 的实例，
        // 而在node.js中是 http.ClientRequest 的实例
      } else {
        // 发送请求时出了点问题
      }
    });
}

export default Request;
