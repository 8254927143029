import $$ from 'cmn-utils';
import _ from 'lodash';

export const epcFilter = (list) => {
  let result = _.map(list, (item) => {
    return { epc: _.replace(item.replace('\r', ''), '>', '') };
  });
  return result;
};

/* eslint-disable no-unused-expressions */
export function copy(value) {
  return JSON.parse(JSON.stringify(value));
}

export function isArrayEqual(value1 = [], value2 = []) {
  let hash = copy(value2);
  if (value1.length === value2.length) {
    for (let i = 0; i < value1.length; i++) {
      const index = hash.indexOf(value1[i]);
      if (index > -1) {
        hash.splice(index, 1);
      } else {
        return false;
      }
    }

    return true;
  }

  return false;
}

export function withPage(obj) {
  if (!obj) return;
  const originalKeys = Object.keys(obj);
  const pageKeys = ['pageNum', 'pageSize', 'total'];
  for (let i in pageKeys) {
    if (originalKeys.indexOf(i) === -1) {
      return false;
    }
  }
  return true;
}

export function isArray(obj) {
  return $$.isArray(obj);
}

export function formatRes(obj) {
  let res;
  if (isArray(obj?.data)) {
    res = { ...obj, data: { list: obj?.data } };
  } else {
    // 当接参类型需要拓展时间则在下面代码添加逻辑
    // if (withPage(obj)) {
    //   res = { ...obj };
    // } else {
    //   res = { ...obj };
    // }
    res = { ...obj };
  }
  return res;
}

export function getQueryVariable(search, variable) {
  var query = search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}

export function constTreeData(rawData) {
  let constData = [];

  rawData?.forEach((node) => {
    let children = [];
    node?.role?.forEach((childNode) => {
      const child = {
        ...childNode,
        isLeaf: true,
        title: childNode.roleName,
        key: `0-${node.id}-${childNode.roleId}`,
      };
      children.push(child);
    });

    let parentNode = {
      ...node,
      title: node.name,
      key: `0-${node.id}`,
      children,
    };

    constData.push(parentNode);
  });
  return constData;
}

export function constMultiSelectOptionsFromOrgList(list) {
  let orgTree = {};
  list?.forEach((item) => {
    if (item?.parentOrgCode) {
      if (orgTree[item?.parentOrgCode]) {
        orgTree[item?.parentOrgCode] = {
          ...orgTree[item?.parentOrgCode],
          children: orgTree[item?.parentOrgCode]?.children?.concat([
            {
              label: item.name,
              value: item.orgCode,
            },
          ]),
        };
      } else {
        orgTree[item?.parentOrgCode] = {
          children: [
            {
              label: item.name,
              value: item.orgCode,
            },
          ],
        };
      }
    } else {
      if (orgTree[item?.orgCode]) {
        orgTree[item?.orgCode] = {
          ...orgTree[item?.orgCode],
          label: item.name,
          value: item.orgCode,
          children: orgTree[item?.orgCode]?.children?.concat([]),
        };
      } else {
        orgTree[item?.orgCode] = {
          label: item.name,
          value: item.orgCode,
          children: [],
        };
      }
    }
  });
  let orgMultiSelectOptions = [];
  for (let j in orgTree) {
    orgMultiSelectOptions.push(orgTree[j]);
  }
  return orgMultiSelectOptions;
}

export const assignParams = (model = {}) => {
  const { list, searchFields, ...pagination } = model;
  return [list, searchFields, pagination];
};

export const parseOptionsIntoDict = (options) => {
  let dict = {};
  options?.forEach((opt) => {
    dict[opt?.value] = opt?.title || opt?.label;
  });
  return dict;
};

export const parseDataIntoOptions = ({ data, label, value }) => {
  let options = [];
  data?.forEach((obj) => {
    options?.push({
      label: obj[label],
      value: obj[value],
    });
  });
  return options;
};

//是否为权限的节点，如果传isSub,探查button权限，Parentpath为按钮的父母path
export const isAuthValue = (targetValue, isSub, parentPath) => {
  let result = '';
  if (isSub) {
    const user = $$.getStore('user', []);
    const resourceList = user.resourceList || [];
    console.log('resourceList:', resourceList, isSub, parentPath)
    result = !resourceList?.some(
      (item) =>
        item.authorityCode == parentPath && item.resourceCode == targetValue
    );
  } else {
    function findFieldByValue(data, targetValue) {
      for (let i = 0; i < data.length; i++) {
        const obj = data[i];
        for (const key in obj) {
          if (obj[key] === targetValue) {
            return true; // 返回结果
          }
          if (typeof obj[key] === 'object') {
            const result = findFieldByValue([obj[key]], targetValue);
            if (result) {
              return result; // 返回结果
            }
          }
        }
      }
      return false; // 没有找到匹配的字段值
    }
    const user = $$.getStore('user', []);
    const menuAuthList = user.menuAuthList || [];
    result = !findFieldByValue(menuAuthList, targetValue);
  }
  return result;
};
