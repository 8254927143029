import axios from "axios";
import { message } from "antd";
axios.interceptors.request.use(
  (config) => {
    let _config = {
      ...config,
    };
    // 测试环境下ops跨域代理
    if (_config?.url?.indexOf("/opsSystem") !== -1) {
      let _url = _config?.url;
      _config.url = _url.replace("/api", "");
    }

    return _config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      // message.error(`错误代码:${error.response.status}, 用户登录信息失效！`);
      toLogin();
      return;
    }
    Promise.reject(error);
  }
);
const toLogin = () => {
  // 清除用户登陆信息
  window.localStorage.clear();
  window.location.hash = '/sign/login';
};
export default axios;