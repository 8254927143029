import {dynamicWrapper, createRoute} from '@/utils/core';

const routesConfig = (app) => ({
  path: '/departmentOrderStatistics',
  title: '科室下单统计',
  component: dynamicWrapper(app, [import('./model')], () => import('./components/index')),
  exact: true
});

export default (app) => createRoute(app, routesConfig);
