import Request from '@/services/globalRequest';
import $$ from 'cmn-utils';
import { message } from 'antd';

// 获取用户信息
export async function getUserInfo(payload) {
  return Request({
    url: `/list_userinfo`,
    params: payload,
  });
}

// 校验LocalStorage中的用户信息是否过期
export function validateUserInfoIsExpired() {
  const createdTime = $$.getStore('user')?.createdTime;
  let isExpired = false;
  if (!createdTime) {
    isExpired = true;
  } else if (Math.floor((Date.now() - createdTime) / (24 * 3600 * 1000)) >= (1/6)) {
    isExpired = true;
  } // 一天以上用户信息过期
  if (isExpired) {
    message.error('用户登录信息已失效，请重新登录！');
  }
  return isExpired;
}
