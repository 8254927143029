import React from 'react';
import { dynamic, router } from 'dva';
import $$ from 'cmn-utils';
import config from '@/config';
import { formatRes } from "@/utils/commonTools";
import Request from "@/services/globalRequest";
const { Route, Switch, Redirect } = router;

/**
 * 生成动态组件
 * @param {*} app
 * @param {*} models
 * @param {*} component
 */
export const dynamicWrapper = (app, models, component) =>
  dynamic({
    app,
    models: () => models,
    component
  });

/**
 * 生成一组路由
 * @param {*} app
 * @param {*} routesConfig
 */
export const createRoutes = (app, routesConfig) => {
  const routes = routesConfig(app)
    .map(config => createRoute(app, () => config))
    .reduce((p, n) => {
      if (n.length) {
        return [...p, ...n];
      } else {
        return p.concat(n);
      }
    }, []);
  return <Switch>{routes}</Switch>;
};
// 路由映射表
window.dva_router_pathMap = {};
/**
 * 生成单个路由
 * @param {*} app
 * @param {*} routesConfig
 */
export const createRoute = (app, routesConfig) => {
  const {
    component: Comp,
    path,
    indexRoute,
    title,
    exact,
    ...otherProps
  } = routesConfig(app);

  if (path && path !== '/') {
    window.dva_router_pathMap[path] = { path, title, ...otherProps };
    // 为子路由增加parentPath
    if (otherProps.childRoutes && otherProps.childRoutes.length) {
      otherProps.childRoutes.forEach(item => {
        if (window.dva_router_pathMap[item.key]) {
          window.dva_router_pathMap[item.key].parentPath = path;
        }
      });
    }
  }

  // 把Redirect放到第一个
  if (indexRoute && $$.isArray(otherProps.childRoutes)) {
    otherProps.childRoutes.unshift(
      <Redirect key={path + '_redirect'} exact from={path} to={indexRoute} />
    );
  }

  const routeProps = {
    key: path || $$.randomStr(4),
    render: props => {
      // 此处可以做路由权限判断
      setDocumentTitle(title);
      return <Comp routerData={otherProps} {...props} />
    }
  };

  return <Route path={path} exact={!!exact} {...routeProps} />;
};

/**
 * 设置页面title
 * @param {*} title 
 */
function setDocumentTitle(title) {
  const documentTitle = config.htmlTitle ? config.htmlTitle.replace(/{.*}/gi, title) : title
  if (documentTitle !== document.title) {
    document.title = documentTitle;
  }
}

// dva的通用effects
export const effects = {
  // 清除特定model
  *clean({ payload }, { put }) {
    if (payload?.model) {
      yield put({
        type: "cleanSpecifiedState",
        key: payload?.model,
      });
    }
  },
  // 清除所有model
  *cleanAll({ payload, callback }, { put }) {
    console.log('清除所有')
    yield put({
      type: "cleanAllState",
    });
  },
  // 搜索
  *search({ payload, callback }, { call, put, select, take }) {
    const response = yield call(Request, payload);
    //  如果是分页查询 (格式化返回参数)
    const judgedResponse = formatRes(response);
    yield put({
      key: payload.model,
      type: "refreshModel",
      payload: {
        searchFields: { ...payload?.searchFields },
        ...judgedResponse,
      },
    });
    if (callback) {
      callback(response);
    }
  },
  // 保存
  *save({ payload, callback }, { call, put, select, take }) {
    const response = yield call(Request, payload);
    if (callback) {
      callback(response);
    }
  },
  // 修改
  *update({ payload, callback }, { call, put }) {
    const response = yield call(Request, payload);
    if (callback) {
      callback(response);
    }
  },
  // 删除
  *delete({ payload, callback }, { call, put, select }) {
    const response = yield call(Request, payload);
    if (callback) {
      callback(response);
    }
  },
};

// dva的通用subscriptions中的setup
export const setup = ({ dispatch, history, LOADED, path }) =>{
  console.log(dispatch, history, LOADED, path)
  history.listen(({ pathname }) => {
    if (pathname !== path && LOADED) {
      LOADED = false; // false 时离开页面后清除缓存, true 时离开页面后保留缓存。
      dispatch({
        type: "cleanAll",
      });
    } else {
      LOADED = true;
    }
  });
}

// dva的reducers
export const reducers = {
  cleanSpecifiedState(state, action) {
    return {
      ...state,
      [action.key]: {},
    };
  },
  cleanAllState() {
    return {};
  },
  refreshModel(state, action) {
    return {
      ...state,
      [action.key]:
        {
          searchFields: action?.payload?.searchFields,
          ...action?.payload?.data,
        } || {},
    };
  },
};